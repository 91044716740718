import React from 'react';

import { ErrorNotice } from './error-notice';
import { ERROR_NOTICE_TYPES, ErrorTypes } from './error-types';

export type ErrorNoticeType = {
    signupFail?: ErrorTypes
};

export function ErrorNoticeByType ({ signupFail }: ErrorNoticeType) {
    const error = signupFail || null;
    const notice = ERROR_NOTICE_TYPES[error || ''] || null;

    if (!error || !notice) {
        return null;
    }

    return (
        <ErrorNotice
            {...notice}
            wrapperProps={{
                // important needed for stack spacing override
                mb: '20px !important'
            }}
        />
    );
}
