import React from 'react';

import {
    SignupPageContent,
    SignupPageStyled,
    SignupRouteStyled
} from '../../app/signup-page.styled';
import { SignupFooter } from './components/signup-footer/signup-footer';
import { SignupForm } from './components/signup-form/signup-form';
import { SignupIntro } from './components/signup-intro/signup-intro';

export function Signup () {
    return (
        <SignupRouteStyled>
            <SignupPageStyled>
                <SignupPageContent>
                    <SignupIntro />
                    <SignupForm />
                </SignupPageContent>
            </SignupPageStyled>
            <SignupFooter />
        </SignupRouteStyled>
    );
}
