import React, { useRef, useEffect } from 'react';

import { useAppSelector } from '../../../../store/store';
import { SignupError } from '../signup-error/signup-error';
import { SignupSuccess } from '../signup-success/signup-success';
import { showForm, showFeedbackScreen } from './anims/anims';
import { SignupFormFields } from './components/signup-form-fields/signup-form-fields';
import { SignupFormCard, SignupFormView, SignupFormWrapper } from './signup-form.styles';

export function SignupForm () {
    const { signupError, signupSuccess, signupFail } = useAppSelector(state => state.signin);
    const formRef = useRef<HTMLDivElement>(null);
    const errorRef = useRef<HTMLDivElement>(null);
    const successRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!signupError && !signupSuccess) {
            if (formRef && formRef.current) {
                showForm(formRef);
            }
        }
    }, [formRef, signupError, signupSuccess]);

    useEffect(() => {
        if (signupError && !signupSuccess) {
            if (errorRef && errorRef.current) {
                showFeedbackScreen(formRef, errorRef);
            }
        }
    }, [formRef, signupError, signupSuccess]);

    useEffect(() => {
        if (!signupError && signupSuccess) {
            if (successRef && successRef.current) {
                showFeedbackScreen(formRef, successRef);
            }
        }
    }, [formRef, signupError, signupSuccess]);

    return (
        <SignupFormView data-testid={'signup-form'}>
            <SignupFormCard>
                <SignupFormWrapper ref={formRef} display={'none'}>
                    <SignupFormFields
                        signupFail={signupFail}
                        signupError={signupError}
                    />
                </SignupFormWrapper>
                <SignupFormWrapper ref={errorRef} display={'none'}>
                    <SignupError />
                </SignupFormWrapper>
                <SignupFormWrapper ref={successRef} display={'none'}>
                    <SignupSuccess />
                </SignupFormWrapper>
            </SignupFormCard>
        </SignupFormView>
    );
}
