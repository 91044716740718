import { chakra, Flex, Box, Stack, FlexProps } from '@chakra-ui/react';
import React from 'react';

export type ErrorNoticeType = {
    errorType: string
    helperText: string
    messageHtml: string
    background: string
    icon: React.ReactNode
};

type Props =
    & ErrorNoticeType
    & { wrapperProps?: FlexProps };

export function ErrorNotice ({
    icon, messageHtml, background, wrapperProps
}: Props) {
    return (
        <Flex
            className={'wrapper'}
            backgroundColor={background}
            borderRadius={'12px'}
            p={'26px'}
            justify={'center'}
            w={'100%'}
            {...wrapperProps}
        >
            <Stack
                direction={{
                    mobile: 'column',
                    tablet: 'row'
                }}
                align={'center'}
                spacing={'20px'}
                maxW={'572px'}
                w={'100%'}
            >
                <Box w={'32px'} h={'32px'}>
                    {icon}
                </Box>

                <chakra.div
                    dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: messageHtml
                    }}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    color={'neutral.900'}
                />
            </Stack>
        </Flex>
    );
}
