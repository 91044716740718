import {
    createSlice, PayloadAction
} from '@reduxjs/toolkit';

import { INITIAL_ROOT_STATE } from '../../../../store/state.types';
import { SigninState } from './signup.types';

export type SignupUserPayload = PayloadAction<SigninState['parentEmail']>;

export type SignupFailPayload = PayloadAction<SigninState['signupFail']>;

export const signupSlice = createSlice({
    name: 'signin',
    initialState: INITIAL_ROOT_STATE,
    reducers: {
        signupUser: (state, action: SignupUserPayload) => {
            return {
                ...state,
                parentEmail: action.payload
            };
        },
        signupFail: (state, action: SignupFailPayload) => {
            return {
                ...state,
                signupFail: action.payload
            };
        },
        signupError: (state) => {
            return {
                ...state,
                signupError: true
            };
        },
        signupSuccess: (state) => {
            return {
                ...state,
                signupSuccess: true
            };
        }
    }
});

const { actions: signupActions } = signupSlice;

export { signupActions };

