import { Image } from '@chakra-ui/react';
import React from 'react';

import successSrc from '../../../../assets/Success.svg';
import { CONFIG } from '../../../../config';
import { useAppSelector } from '../../../../store/store';
import { ImageHolder, SignupContent, ReportTitle, ReturnToPortalButton, ConfirmationText } from '../signup-form/signup-form.styles';

export function SignupSuccess () {
    const gotoParentPortal = () => {
        window.open(CONFIG.parentPortal, '_self');
    };
    const parentEmail = useAppSelector(state => state.signin.parentEmail || 'your email');

    return (
        <SignupContent>
            <ImageHolder>
                <Image src={successSrc} marginBottom={'20px'} />
            </ImageHolder>
            <ReportTitle>Hooray!</ReportTitle>
            <ReportTitle>Thanks for signing up</ReportTitle>
            <ConfirmationText
                textStyle={'pageBody'}
            >We’ve sent an email to &apos;<b>{parentEmail}</b>&apos; confirming your subscription. Starting next week,
                you’ll start receiving the reports.
            </ConfirmationText>
            <ReturnToPortalButton
                data-testid={'parent-portal-button'}
                size={'large'}
                type={'submit'}
                onClick={gotoParentPortal}
            >
                Return to the Parent Portal
            </ReturnToPortalButton>
        </SignupContent>
    );
}
