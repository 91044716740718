import type { PropsWithChildren } from 'react';
import React from 'react';
import {
    QueryClient, QueryClientProvider
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // This refetchOnWindowFocus setting is not useful as the data does not update much,
            // also it was mentioned from BS there are api limits on requests.
            refetchOnWindowFocus: false
        }
    }
});

export function ApiProvider ({
    children, client = queryClient
}: PropsWithChildren<{
    client?: QueryClient
}>) {
    return (
        <QueryClientProvider client={client}>
            <>
                {children}
                <ReactQueryDevtools initialIsOpen={false} />
            </>
        </QueryClientProvider>
    );
}
