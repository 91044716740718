import * as Yup from 'yup';

import { TextInputControlError } from '../../../../../components/formik/fields/text-input-control-field';
import { SignupForm } from '../../signup-model/signup.types';

export function createFormSchema () {
    return Yup.object()
        .shape({
            studentUsername: Yup.string()
                .required('Your user name is required'),
            studentPassword: Yup.string()
                .required('Your student password is required'),
            parentFirstName: Yup.string()
                .required('Your first name is required'),
            parentLastName: Yup.string()
                .required('Your last name is required'),
            parentEmail: Yup.string()
                .required('Required')
                .email('Invalid email')
        }).required();
}

export const EMPTY_FORM: SignupForm = {
    studentUsername: '',
    studentPassword: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: ''
};

export const EMAIL_VALIDATION_ERRORS: Record<string, TextInputControlError> = {
    EMAIL_DOES_NOT_BELONG_TO_PARENT: {
        props: {
            isInvalid: true
        }
    },
    PARENT_STUDENT_REGION_MISMATCH: {
        props: {
            isInvalid: true
        }
    }
};

export const CHILD_VALIDATION_ERRORS: Record<string, TextInputControlError> = {
    ALREADY_REGISTERED: {
        props: {
            isInvalid: false,
            isUserWarning: true
        }
    },
    INVALID_LICENCE: {
        props: {
            isInvalid: true
        }
    },
    INVALID_STUDENT_CREDENTIALS: {
        props: {
            isInvalid: true
        }
    }
};
