import { theme as baseMathleticsTheme } from '@3plearning/chakra-mathletics-theme';
import { extendTheme } from '@chakra-ui/react';

import { breakpoints } from './foundations/breakpoints';
import * as app from './foundations/colors';
import { layerStyles } from './foundations/layer-styles';
import { shadows } from './foundations/shadows';
import styles from './foundations/styles';
import { textStyles } from './foundations/text-styles';

/**
 * Extend the base shared theme with the custom colors and variants in portal.
 *
 * @see https://chakra-ui.com/docs/theming/customize-theme
 */
const theme = extendTheme(baseMathleticsTheme, {
    styles,
    colors: { app },
    layerStyles,
    textStyles,
    breakpoints,
    shadows,
    global: () => ({
        a: {
            color: 'primary.600',
            cursor: 'pointer',
            textDecoration: 'underline',
            outline: 'none',
            letterSpacing: '0.15px',
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: '16px',
            _hover: { color: 'primary.700' }
        }
    })
});

export default theme;
