import {
    chakra, Flex
} from '@chakra-ui/react';

export const Card = chakra(Flex, {
    baseStyle: {
        width: '100%',
        background: 'white',
        borderRadius: '20px',
        flexDirection: 'column',
        alignItems: 'center',
        filter: 'drop-shadow(0px 4px 3px rgba(125,137,153,0.8))'
    }
});
