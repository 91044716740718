import React, {
    createContext, PropsWithChildren, useContext, useMemo
} from 'react';

import type { EnvConfig } from '../global.types';

export const EnvConfigContext = createContext<EnvConfig>({} as EnvConfig);

export type EnvConfigProviderProps = PropsWithChildren<{
    config?: EnvConfig
}>;

/**
 * A Basic React provider to consume the env config values using a standard React dependency injection pattern.
 * This allows for easier mocking in tests and storybook etc.
 *
 * These values are exposed through the CMD `env.sh` script to create 'env-config.js'.
 * The values are then available to the runtime on the window object `window._env_`.
 */
export function EnvConfigProvider ({
    children, config = window._env_ || {}
}: EnvConfigProviderProps) {
    /**
     * The bash env.sh script does not handle booleans,
     * this sets the correct type Javascript type.
     */
    const configParsed = useMemo(() => {
        return Object.fromEntries(Object
            .entries(config)
            .map(([key, value]) => {
                const parsedValue = (value === 'true' || value === 'false') ?
                    value == 'true' : value;

                return [key, parsedValue];
            })
        ) as EnvConfig;
    }, [config]);

    return (
        <EnvConfigContext.Provider value={configParsed}>
            {children}
        </EnvConfigContext.Provider>
    );
}

/**
 * Basic hook for using the application environment config values,
 * see Readme.md -> Using env config for usage guide.
 */
export function useEnvConfig () {
    return useContext(EnvConfigContext);
}
