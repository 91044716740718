import { useFormik, FormikHelpers } from 'formik';
import { Dispatch, useMemo } from 'react';

import { useEnvConfig } from '../../../../../env-config';
import { useAppDispatch } from '../../../../../store/store';
import { signupActions, SignupFailPayload, SignupUserPayload } from '../../signup-model/signup.slice';
import { SignupForm, SubmitValues } from '../../signup-model/signup.types';
import {
    EMPTY_FORM,
    createFormSchema
} from '../__utils__/form-validation';
import { ErrorTypes, UserProfileTypes } from '../error-notice/error-types';

export function useSignupForm () {
    const { API_URL } = useEnvConfig();
    const formSchema = useMemo(() => createFormSchema(), []);
    const dispatch = useAppDispatch();
    const onSubmit = (values: SubmitValues, { setSubmitting }: FormikHelpers<SubmitValues>) => {
        fetch(
            `${API_URL}/parent-registration`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(values)
            }
        )
            .then(async (response) => {
                setSubmitting(false);
                if (response.ok) {
                    await onSignupRequestSuccess({
                        dispatch,
                        response,
                        values
                    });
                }
                if (!response.ok) {
                    await onSignupRequestError({
                        dispatch,
                        response
                    });
                }
            })
            .catch((error: unknown) => {
                dispatch(signupActions.signupError());
            });
    };
    const initialValues = {
        ...EMPTY_FORM
    };
    const formValue = useFormik({
        initialValues,
        onSubmit,
        validationSchema: formSchema,
        validateOnBlur: true,
        validateOnChange: false
    });

    return {
        formValue
    };
}

type OnSignupRequestSuccessParams = {
    dispatch: Dispatch<SignupUserPayload | SignupFailPayload>
    values: SignupForm
    response: any
};

export async function onSignupRequestSuccess ({ dispatch, values, response }: OnSignupRequestSuccessParams) {
    dispatch(signupActions.signupUser(values.parentEmail));

    const { status } = response;

    switch(status) {
    case 200:
    case 201:
        return dispatch(signupActions.signupSuccess());
    default:
        return dispatch(signupActions.signupError());
    }
}

type OnSignupRequestErrorParams = {
    dispatch: Dispatch<SignupUserPayload | SignupFailPayload>
    response: any
};

export async function onSignupRequestError ({ dispatch, response }: OnSignupRequestErrorParams) {
    const { status } = response;

    let errorCode;
    let returnedUserProfileType;

    if (status !== 200 && status !== 201) {
        const {
            code,
            userProfileType
        } = await response.json();

        errorCode = code;
        returnedUserProfileType = userProfileType;
    }

    switch(status) {
    case 400:
        switch (errorCode) {
        case ErrorTypes.invalidLicence: {
            if (returnedUserProfileType === UserProfileTypes.homeStudent) errorCode = ErrorTypes.invalidLicenceStudent;

            return dispatch(signupActions.signupFail(errorCode));
        }
        case ErrorTypes.emailDoesNotBelongToParent:
        case ErrorTypes.parentStudentRegionMismatch:
        case ErrorTypes.alreadyRegistered:
            return dispatch(signupActions.signupFail(errorCode));
        default:
            return dispatch(signupActions.signupError());
        }
    case 401:
        return dispatch(signupActions.signupFail(errorCode));
    default:
        return dispatch(signupActions.signupError());
    }
}
