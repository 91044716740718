import { Box, Image, Flex, Center } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

import {
    PageContainer,
    ContentContainer,
    ContentFlex,
    TextBox,
    HeaderText,
    BodyText
} from './display-message.styles';

type DisplayMessageProps = {
    headerImage: string
    headerText: string
    contentText: ReactElement
    contentImage: string
    contentImageStyles: object
};

export const DisplayMessage = ({
    headerImage,
    headerText,
    contentText,
    contentImage,
    contentImageStyles
}: DisplayMessageProps) => {
    return (
        <PageContainer>
            <Center height={'100%'}>
                <ContentContainer>
                    <ContentFlex>
                        <Box>
                            <Image
                                src={headerImage}
                                width={'120px'}
                                height={'44px'}
                                marginBottom={'100px'}
                            />
                        </Box>
                        <Flex>
                            <TextBox>
                                <HeaderText>
                                    {headerText}
                                </HeaderText>
                                <BodyText>
                                    {contentText}
                                </BodyText>
                            </TextBox>
                            <Box position={'relative'}>
                                <Image
                                    src={contentImage}
                                    {...contentImageStyles}
                                />
                            </Box>
                        </Flex>
                    </ContentFlex>
                </ContentContainer>
            </Center>
        </PageContainer>
    );
};
