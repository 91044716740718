import { RippleButton } from '@3plearning/chakra-mathletics-theme';
import {
    Box, chakra, Text, Flex
} from '@chakra-ui/react';
import React from 'react';

import { Card } from '../../../../styles/component.styles';

export const SignupFormView = chakra('div', {
    label: 'SignupFormView',
    baseStyle: {
        margin: {
            desktopWide: '15px 8px 0 113px',
            mobile: '32px 0 0 0',
            tablet: '32px 0 0 0'
        }
    }
});

export const SignupFormCard = chakra(Card, {
    label: 'SignupFormCard',
    baseStyle: {
        width: {
            desktopWide: '480px',
            mobile: '100vw',
            tablet: '90vw'
        },
        borderRadius: '20px',
        overflow: 'hidden'
    }
});

export const SignupFormWrapper = chakra(Box, {
    label: 'SignupFormWrapper',
    baseStyle: {
        width: '100%'
    }
});

export const SignupFormContent = chakra(Box, {
    label: 'SignupFormContent',
    baseStyle: {
        margin: {
            desktopWide: '40px 56px',
            mobile: '20px',
            tablet: '20px'
        },
        width: {
            desktopWide: 'calc(100% - 112px)',
            mobile: 'calc(100% - 40px)',
            tablet: 'calc(100% - 40px)'
        },
        height: {
            desktopWide: 'calc(100% - 80px)',
            mobile: 'calc(100% - 40px)',
            tablet: 'calc(100% - 40px)'
        }
    }
});

export const ReportTitle = ({ ...restProps }) => (
    <Text
        textStyle={'pageTitle'}
        {...restProps}
        sx={{
            label: 'FormTitle',
            marginBottom: {
                desktopWide: '24px',
                mobile: '0',
                tablet: '24px'
            },
            height: {
                mobile: '40px'
            },
            display: 'flex',
            alignItems: 'center'
        }}
    />
);

export const SignupContent = chakra('div', {
    label: 'SignupFormContent',
    baseStyle: {
        margin: '40px 40px',
        width: 'calc(100% - 80px)',
        height: 'calc(100% - 80px)'
    }
});

export const ImageHolder = chakra(Flex, {
    baseStyle: {
        width: '100%',
        alignItems: 'center',
        flexFlow: 'column'
    }
});

export const SubmitButton = chakra(RippleButton, {
    baseStyle: {
        width: {
            desktopWide: '180px',
            mobile: '100%',
            tablet: '180px'
        },
        marginTop: '25px'
    }
});

export const ReturnToPortalButton = chakra(SubmitButton, {
    baseStyle: {
        width: {
            desktopWide: '270px',
            mobile: '100%',
            tablet: '270px'
        }
    }
});

export const ConfirmationText = chakra(Text, {
    baseStyle: {
        marginTop: {
            mobile: '20px'
        }
    }
});
