import React, { PropsWithChildren } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import type { Store } from 'redux';

import { RootState } from './state.types';
import {
    store as defaultStore
} from './store';

export function ReduxStoreProvider ({
    children, store = null
}: PropsWithChildren<{
    store?: Store<RootState> | null
}>) {
    store = store || defaultStore;

    return (
        <ReduxProvider store={store}>
            {children}
        </ReduxProvider>
    );
}
