import { chakra, Flex, Text, SystemStyleObject, Box } from '@chakra-ui/react';

export const SignupFooterStyled = chakra(Flex, {
    label: 'SignupFooterStyled',
    baseStyle: {
        width: '100%',
        backgroundColor: '#4E5C67',
        alignItems: {
            mobile: '',
            tablet: 'start',
            desktopWide: 'center'
        },
        flexFlow: 'column',
        paddingLeft: {
            mobile: '10px',
            tablet: '10px'
        }
    }
});

export const FooterContent = chakra(Flex, {
    label: 'FooterContent',
    baseStyle: {
        m: {
            mobile: '25px 0 0 0',
            tablet: '40px 0 0 10px',
            desktopWide: '40px 0 20px 0'
        },
        width: {
            mobile: '100%',
            tablet: '100%',
            desktopWide: '1136px'
        },
        flexDirection: {
            mobile: 'column',
            tablet: 'column',
            desktopWide: 'row'
        },
        alignItems: 'start'
    }
});

export const LinksContent = chakra('div', {
    label: 'LinksContent',
    baseStyle: {
        width: {
            mobile: 'auto',
            tablet: 'calc(100% - 30px)',
            desktopWide: 'calc(100% - 238px)'
        },
        margin: {
            mobile: '0 20px 0 10px',
            tablet: '0 20px 0 0',
            desktopWide: '0 20px 0 10px'

        },
        height: {
            mobile: '635px',
            tablet: '600px',
            desktopWide: 'auto'
        }
    }
});

export const ListTitle = chakra(Text, {
    label: 'ListTitle',
    baseStyle: {
        color: 'white',
        fontSize: '22px',
        lineHeight: '20px',
        marginBottom: '10px'
    }
});

export const LinkStyles: SystemStyleObject = {
    color: 'white',
    textDecoration: 'none',
    fontSize: '16px',
    lineHeight: '25px',
    transition: 'all',
    fontWeight: 'unset',
    outline: 'none',
    _focus: {
        outline: 'none'
    },
    _hover: { color: 'primary.500' }
};

export const FlexColumn = chakra(Flex, {
    label: 'ListGroup',
    baseStyle: {
        flexDirection: 'column',
        marginBottom: '30px',
        fontSize: '14px'
    }
});

export const LineSpacer = chakra('div', {
    label: 'LineSpacer',
    baseStyle: {
        m: {
            mobile: '0 0 25px 0',
            tablet: '0 0 35px 0',
            desktopWide: '0 0 35px 0'
        },
        width: '100%',
        borderTop: 'solid white 1px'
    }
});

export const FooterLinks = chakra(Flex, {
    label: 'FooterLinks',
    baseStyle: {
        width: {
            mobile: 'auto',
            tablet: 'auto',
            desktopWide: '100%'
        },
        justifyContent: {
            mobile: 'unset',
            tablet: 'unset',
            desktopWide: 'space-between'
        },
        flexDirection: {
            mobile: 'column',
            tablet: 'column',
            desktopWide: 'row'
        },
        alignItems: 'start'
    }
});

export const BottomFooter = chakra(Flex, {
    label: 'BottomFooter',
    baseStyle: {
        width: '100%',
        flexDirection: {
            mobile: 'column',
            tablet: 'row',
            desktopWide: 'row'
        },
        justifyContent: {
            mobile: 'start',
            tablet: 'space-between',
            desktopWide: 'space-between'
        }
    }
});

export const CopyText = chakra(Box, {
    label: 'CopyText',
    baseStyle: {
        color: 'white',
        fontSize: '12px',
        textAlign: {
            mobile: 'center',
            tablet: 'left'
        }
    }
});

export const SocialLinksContainer = chakra(Flex, {
    label: 'SocialLinksContainer',
    baseStyle: {
        justifyContent: 'center'
    }
});

export const SocialLinks = chakra(Flex, {
    label: 'SocialLinks',
    baseStyle: {
        width: '165px',
        justifyContent: 'space-between',
        marginTop: {
            mobile: '15px',
            tablet: 'unset',
            desktopWide: 'unset'
        }
    }
});

export const LogoStyles: SystemStyleObject = {
    position: 'relative',
    mx: {
        mobile: '8px',
        tablet: '8px',
        footerTablet: '6px',
        desktop: '12px'
    },
    width: '24px',
    height: '24px',
    margin: '0 5px',
    cursor: 'pointer',
    _first: {
        marginLeft: '0'
    },
    _last: {
        marginRight: '0'
    }
};
