import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useEnvConfig } from '../../env-config';
import { UnsubscribeError } from './components/unsubscribe-error/unsubscribe-error';
import { UnsubscribeSuccess } from './components/unsubscribe-success/unsubscribe-success';

type UnsubscribeUrlParams = {
    unsubscribeId: string
};

export function Unsubscribe () {
    const { API_URL } = useEnvConfig();
    const { unsubscribeId } = useParams<UnsubscribeUrlParams>();
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

    useEffect(() => {
        if (unsubscribeId) {
            fetch(
                `${API_URL}/parent-registration/email-reports-subscription?unsubscribeId=${unsubscribeId}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'DELETE'
                }
            )
                .then((response) => {
                    if (response.ok) {
                        setIsSuccess(response.status === 200);
                    }
                    if (!response.ok) {
                        setIsSuccess(false);
                    }
                })
                .catch((error: unknown) => {
                    setIsSuccess(false);
                });
        }
    }, [API_URL, unsubscribeId]);

    switch(isSuccess) {
    case true: return <UnsubscribeSuccess />;
    case false: return <UnsubscribeError />;
    default: return null;
    }
}
