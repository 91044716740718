/**
 * These breakpoints are specific to this application's components.
 * They are prefixed to the feature that uses them.
 *
 * @see for usage in props and hooks https://chakra-ui.com/docs/styled-system/features/responsive-styles
 *
 */
export const breakpoints = {
    desktopWide: '1200px',
    tablet: '449px'
};
