import {
    TextInputControl, TextInputControlProps
} from '@3plearning/chakra-mathletics-theme';
import { WarningIcon } from '@chakra-ui/icons';
import { useField } from 'formik';
import React from 'react';

import { ErrorTypes } from '../../../routes/signup/components/signup-form/error-notice/error-types';

export type TextInputControlError = {
    helperText?: string
    props?: Omit<TextInputControlProps, 'id' | 'helperText'>
};

type Props =
    & TextInputControlProps
    & { signupFail?: ErrorTypes }
    & {
    /**
     * This object is a record of custom errors to handle scenarios
     * such as `isUserWarning` or `isWarning` which come from validation
     * "errors" but are treated as custom error types for UX.
     */
    customErrors?: Record<string, TextInputControlError>
};

export function TextInputControlField ({ signupFail, customErrors, ...props }: Props) {
    const [field, meta] = useField({
        type: 'text',
        id: props.id,
        name: props.id
    });
    const { defaultValue: _, ...existingProps } = props.inputProps || {};
    const inputProps = {
        ...existingProps,
        ...field
    };
    const isInvalid = !!meta.error && meta.touched;
    const helperText = meta.touched ? meta.error : undefined;

    function getPropsOverride () {
        const hasMetaError = customErrors && meta?.error && !!customErrors[meta.error];
        const hasSignupFailed = customErrors && signupFail && customErrors[signupFail];

        let propsOverride: Omit<TextInputControlProps, 'id'> | null = null;

        if (hasMetaError && meta?.error) {
            const customError = customErrors[meta.error];

            propsOverride = {
                helperText: customError.helperText,
                ...customError.props
            };
        }

        if (hasSignupFailed) {
            const customError = customErrors[signupFail];

            propsOverride = {
                ...customError.props
            };
        }

        return propsOverride;
    }

    function getRightAdornment () {
        if (isInvalid) {
            switch (field.name) {
            case 'parentEmail': return <WarningIcon color={'red.500'} />;
            default: return null;
            }
        }

        return null;
    }

    return (
        <TextInputControl
            {...props}
            data-testid={props['data-testid'] || props.id}
            inputProps={inputProps}
            isInvalid={isInvalid}
            helperText={helperText}
            RightAdornment={getRightAdornment()}
            {...getPropsOverride()}
        />
    );
}
