import { CONFIG } from '../../../../config';
import FacebookLogoSrc from './assets/Facebook.svg';
import InstagramLogoSrc from './assets/Instagram.svg';
import LinkedInLogoSrc from './assets/LinkedIn.svg';
import TwitterLogoSrc from './assets/Twitter.svg';
import YouTubeLogoSrc from './assets/YouTube.svg';

type LinkMenuItem = {
    label: string
    url: string
};

export type LinkMenuData = {
    links: Array<LinkMenuItem>
    title: string
};

export const FOR_SCHOOLS_LINKS_MENU: LinkMenuData = {
    title: 'About Mathletics',
    links: [
        {
            label: 'For Schools',
            url: CONFIG.mathleticsForSchoolsUrl
        },
        {
            label: 'For Home Users',
            url: CONFIG.mathleticsHomeUrl
        },
        {
            label: 'Features',
            url: CONFIG.mathleticsFeaturesUrl
        },
        {
            label: 'Curriculum Aligned',
            url: CONFIG.mathleticsAustralianCurriculumUrl
        },
        {
            label: 'Help & Support',
            url: CONFIG.mathleticsSupportUrl
        },
        {
            label: 'Technical Support',
            url: CONFIG.mathleticsTechSupportUrl
        }
    ]
};

export const ABOUT_LINKS_MENU: LinkMenuData = {
    title: 'About 3P Learning',
    links: [
        {
            label: 'About us',
            url: CONFIG.mathleticsAboutUrl
        },
        {
            label: 'Careers',
            url: CONFIG.mathleticsCareersUrl
        },
        {
            label: 'Contact',
            url: CONFIG.mathleticsContactUrl
        },
        {
            label: 'Privacy Policy',
            url: CONFIG.privacyPolicyUrl
        },
        {
            label: 'Terms & Conditions',
            url: CONFIG.termsAndConditionsUrl
        }
    ]
};

export const MORE_LINKS_MENU: LinkMenuData = {
    title: 'More from 3P Learning',
    links: [
        {
            label: 'Reading Eggs',
            url: CONFIG.mathleticsReadingEggsUrl
        },
        {
            label: 'Mathseeds',
            url: CONFIG.mathleticsMathSeedsUrl
        }
    ]
};

type SocialMediaLink =
    & LinkMenuItem
    & {
    iconSrc: string
};

export type SocialMediaMenuData = Array<SocialMediaLink>;

export const SOCIAL_MEDIA_LINKS: SocialMediaMenuData = [
    {
        label: 'twitter',
        iconSrc: TwitterLogoSrc,
        url: CONFIG.twitterUrl
    },
    {
        label: 'instagram',
        iconSrc: InstagramLogoSrc,
        url: CONFIG.instagramUrl
    },
    {
        label: 'linkedin',
        iconSrc: LinkedInLogoSrc,
        url: CONFIG.linkedinUrl
    },
    {
        label: 'youtube',
        iconSrc: YouTubeLogoSrc,
        url: CONFIG.youtubeUrl
    },
    {
        label: 'facebook',
        iconSrc: FacebookLogoSrc,
        url: CONFIG.facebookUrl
    }
];
