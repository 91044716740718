import type { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
    global: () => ({
        html: {
            // The design includes various backgrounds that overlap,
            // this prevents the horizontal scroll.
            overflowX: 'hidden'
        },
        body: {
            width: '490px',
            height: '825px',
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            background: 'none'
        }
    })
};

export default styles;
