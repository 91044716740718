import { chakra, Flex, Box, Text } from '@chakra-ui/react';

export const PageContainer = chakra(Box, {
    baseStyle: {
        width: '100vw',
        height: '100vh'
    }
});

export const ContentContainer = chakra(Box, {
    baseStyle: {
        boxShadow: '0 2px 8px rgb(141 156 161 / 24%)',
        borderRadius: '20px'
    }
});

export const ContentFlex = chakra(Flex, {
    baseStyle: {
        width: '746px',
        flexDirection: 'column',
        margin: '40px 58px 120px 62px'
    }
});

export const TextBox = chakra(Box, {
    baseStyle: { maxWidth: '455px' }
});

export const HeaderText = chakra(Text, {
    baseStyle: {
        fontWeight: 400,
        textStyle: 'pageTitle',
        marginBottom: '30px',
        whiteSpace: 'break-spaces'
    }
});

export const BodyText = chakra(Text, {
    baseStyle: {
        fontWeight: 400,
        textStyle: 'pageBody'
    }
});
