import React from 'react';

import mathleticsImageSrc from '../../../../assets/mathletics-logo.svg';
import successSrc from '../../../../assets/Success.svg';
import { DisplayMessage } from '../../../../components/display-message/display-message';
import { CONFIG } from '../../../../config';

export function UnsubscribeSuccess () {
    const contentText = (
        <>
            You’ll no longer receive weekly reports, but you can&nbsp;
            <a
                href={`${CONFIG.parentPortalSignUp}`}
                target={'_blank'}
                rel={'noreferrer'}
            >
                resubscribe at any time here
            </a>.
        </>
    );
    const contentImageStyles = {
        width: '226px',
        height: '175px',
        position: 'absolute',
        top: '-30px',
        maxWidth: 'unset'
    };

    return (
        <DisplayMessage
            headerImage={mathleticsImageSrc}
            headerText={'You\'ve been unsubscribed.'}
            contentText={contentText}
            contentImage={successSrc}
            contentImageStyles={contentImageStyles}
        />
    );
}
