/* istanbul ignore file */
import { gsap } from 'gsap';
import { MutableRefObject } from 'react';

type RefType = MutableRefObject<HTMLDivElement | null>;

const duration = 0.5;
const delay = 0.5;

/*
* SHOW FORM TWEEN
* */
export const showForm = (formRef: RefType) => {
    const form = formRef.current;

    gsap.timeline()
        .set(form, {
            x: -500,
            y: 0,
            height: 0,
            opacity: 0,
            display: 'block'
        })
        .to(form,
            {
                x: -500,
                opacity: 1,
                height: 'auto',
                duration,
                delay
            })
        .to(form,
            {
                duration: 0,
                opacity: 1,
                x: 0
            });
};

/*
* SHOW FEEDBACK TWEEN
* */

export const showFeedbackScreen = (formRef: RefType, feedbackScreen: RefType) => {
    const form = formRef.current;
    const feedback = feedbackScreen.current;

    gsap.timeline()
        .to(form, {
            delay,
            duration,
            opacity: 0,
            height: 3,
            onComplete: () => {
                gsap.to(form, {
                    display: 'none'
                });
            }
        })
        .fromTo(feedback, {
            display: 'block',
            opacity: 0,
            height: 3,
            x: -500
        },
        {
            height: 'auto',
            duration,
            opacity: 1,
            delay,
            onComplete: () => {
                gsap.to(feedback, {
                    ease: 'back.out(2)',
                    x: 0
                });
            }
        });
};
