import React from 'react';

import { RouteManager } from '../routes/route-manager';
import { AppProviders } from './providers/app-providers';

/**
 * The main app composition used in production.
 */
function App () {
    return (
        <AppProviders>
            <RouteManager />
        </AppProviders>
    );
}

export default App;
