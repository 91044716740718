import { Center, chakra, Flex } from '@chakra-ui/react';

export const SignupPageStyled = chakra(Center, {
    label: 'SignupPageStyled',
    baseStyle: {
        width: '100%',
        height: 'auto',
        background: 'linear-gradient(180deg, #7ACED7 0%, #21BBF7 100%)'
    }
});

export const SignupPageContent= chakra(Flex, {
    label: 'SignupPageContent',
    baseStyle: {
        padding: {
            desktopWide: '40px 72px 107px 72px',
            mobile: '20px 0',
            tablet: '40px 0'
        },
        flexDirection: {
            mobile: 'column',
            tablet: 'column',
            desktopWide: 'row'
        },
        alignItems: {
            desktopWide: 'flex-start',
            mobile: 'center',
            tablet: 'center'
        },
        width: {
            mobile: 'calc(100% - 40px)',
            tablet: 'calc(100% - 40px)',
            desktopWide: 'auto'
        },
        margin: {
            mobile: '0 20px',
            tablet: '0 20px',
            desktopWide: '0'
        },
        minHeight: {
            desktopWide: '940px'
        }
    }
});

export const SignupRouteStyled = chakra('div', {
    label: 'SignupRouteStyled',
    baseStyle: {
        width: '100vw',
        height: '100vh'
    }
});
