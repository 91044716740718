import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Signup } from './signup/signup';
import { Unsubscribe } from './unsubscribe/unsubscribe';

export function RouteManager () {
    return (
        <Routes>
            <Route path={'/signup'} element={<Signup />} />
            <Route path={'/unsubscribe/:unsubscribeId'} element={<Unsubscribe />} />
        </Routes>
    );
}
