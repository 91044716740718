import { configureStore } from '@reduxjs/toolkit';
import {
    TypedUseSelectorHook, useDispatch, useSelector
} from 'react-redux';

import { rootReducers } from './root-reducer';
import type { RootState } from './state.types';

export const store = configureStore<RootState>({
    reducer: rootReducers,
    devTools: process.env.NODE_ENV === 'development' ? {
        name: 'weekly-parent-report-signup',
        trace: true
    } : false
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

