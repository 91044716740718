import { Stack, Text } from '@chakra-ui/react';
import { Form, FormikProvider } from 'formik';
import React, { useEffect, useState } from 'react';

import { TextInputControlField } from '../../../../../../components/formik/fields/text-input-control-field';
import { CHILD_VALIDATION_ERRORS, EMAIL_VALIDATION_ERRORS } from '../../__utils__/form-validation';
import { ErrorNoticeByType } from '../../error-notice/error-notice-by-type';
import { ErrorTypes } from '../../error-notice/error-types';
import { useSignupForm } from '../../hooks/use-signup-form';
import { ReportTitle, SignupFormContent, SubmitButton } from '../../signup-form.styles';

type SignupFormProp = {
    signupFail?: ErrorTypes
    signupError?: boolean
};

export function SignupFormFields ({ signupFail, signupError }: SignupFormProp) {
    const {
        formValue
    } = useSignupForm();
    const disableField = formValue.isSubmitting || formValue.isValidating;
    const [showTopNotice, setShowTopNotice] = useState<boolean | null>();

    useEffect(() => {
        if (typeof signupFail !== 'undefined') {
            setShowTopNotice((
                signupFail !== ErrorTypes.parentStudentRegionMismatch
            ) && (
                signupFail !== ErrorTypes.emailDoesNotBelongToParent
            ));
        }
    }, [signupFail]);

    return (
        <SignupFormContent data-testid={'signup-form-content'}>
            <ReportTitle>Parent Report Sign Up</ReportTitle>
            <FormikProvider
                value={formValue}
                data-testid={'signup-form-fields'}
            >
                <Stack
                    as={Form}
                    noValidate={true}
                    marginBottom={'16px'}
                >
                    <Text
                        textStyle={'pageSubtitle'}
                        w={'100%'}
                        marginBottom={'15px'}
                    >
                        1. Your child&apos;s details
                    </Text>
                    {showTopNotice &&
                        <ErrorNoticeByType signupFail={signupFail} />
                    }
                    <TextInputControlField
                        id={'studentUsername'}
                        labelText={'Mathletics student username'}
                        isRequired={true}
                        customErrors={CHILD_VALIDATION_ERRORS}
                        signupFail={signupFail}
                        isDisabled={disableField}
                    />
                    <TextInputControlField
                        id={'studentPassword'}
                        labelText={'Mathletics password'}
                        isRequired={true}
                        customErrors={CHILD_VALIDATION_ERRORS}
                        signupFail={signupFail}
                        inputProps={{ type: 'password' }}
                        isDisabled={disableField}
                    />
                </Stack>
                <Stack
                    as={Form}
                    noValidate={true}
                >
                    <Text
                        textStyle={'pageSubtitle'}
                        w={'100%'}
                        marginBottom={'15px'}
                    >
                        2. Your details
                    </Text>
                    <TextInputControlField
                        id={'parentFirstName'}
                        labelText={'Your first name'}
                        isRequired={true}
                        isDisabled={disableField}
                    />

                    <TextInputControlField
                        id={'parentLastName'}
                        labelText={'Your last name'}
                        isRequired={true}
                        isDisabled={disableField}
                    />

                    {(!showTopNotice && typeof showTopNotice !== null) &&
                        <ErrorNoticeByType signupFail={signupFail} />
                    }

                    <TextInputControlField
                        id={'parentEmail'}
                        labelText={'Email'}
                        isRequired={true}
                        customErrors={EMAIL_VALIDATION_ERRORS}
                        signupFail={signupFail}
                        isDisabled={disableField}
                    />
                    <SubmitButton
                        isLoading={disableField || signupError}
                        data-testid={'submit-button'}
                        size={'large'}
                        type={'submit'}
                    >
                        Submit
                    </SubmitButton>
                </Stack>
            </FormikProvider>
        </SignupFormContent>
    );
}
