import {
    SigninState,
    INITIAL_SIGNIN_STATE
} from '../routes/signup/components/signup-model/signup.types';

export type RootState = {
    signin: SigninState
};

export const INITIAL_ROOT_STATE: RootState = {
    signin: INITIAL_SIGNIN_STATE
};

