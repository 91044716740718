import { ErrorTypes } from '../signup-form/error-notice/error-types';

export type SubmitValues = {
    parentEmail: string
    parentFirstName?: string | null
    parentLastName?: string | null
    studentPassword?: string | null
    studentUsername?: string | null
};

export type SigninState = {
    studentUsername?: string | null
    studentPassword?: string | null
    parentFirstName?: string | null
    parentLastName?: string | null
    parentEmail: string
    signupFail?: ErrorTypes
    signupError?: boolean
    signupSuccess?: boolean
};

export type SignupForm = Omit<SigninState, 'signupSuccess' | 'signupError' | 'signupFail'>;

export const INITIAL_SIGNIN_STATE: SigninState = {
    studentUsername: null,
    studentPassword: null,
    parentFirstName: null,
    parentLastName: null,
    parentEmail: '',
    signupFail: undefined,
    signupError: false,
    signupSuccess: false
};
