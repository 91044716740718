import { chakra, Skeleton, Image, Box, Text } from '@chakra-ui/react';

const LogoSize = {
    width: '158px',
    height: '57px'
};
const IntroImageSize = {
    width: {
        desktopWide: '557px',
        mobile: '60%',
        tablet: '448px'
    },
    height: {
        desktopWide: '457px',
        mobile: '40%',
        tablet: '328px'
    }
};

export const SignupIntroBox = chakra(Box, {
    label: 'SignupIntroBox',
    baseStyle: {
        maxWidth: {
            mobile: '100vh',
            tablet: '100vh',
            desktopWide: '535px'
        },
        minWidth: {
            tablet: '356px',
            desktopWide: '557px'
        },
        height: {
            wideDesktop: '774px',
            mobile: 'auto',
            tablet: 'auto'
        }
    }
});

export const MathsLogo = chakra(Image, {
    baseStyle: {
        ...LogoSize,
        alt: 'mathletics-logo',
        width: {
            mobile: '120px',
            tablet: '158px',
            desktopWide: '158px'
        },
        marginLeft: {
            mobile: '10px',
            tablet: 'unset',
            desktopWide: 'unset'
        },
        marginBottom: {
            mobile: '30px',
            tablet: '30px',
            desktopWide: '0'
        },
        marginRight: {
            mobile: 'unset',
            tablet: 'unset',
            desktopWide: '80px'
        }
    }
});

export const Title = chakra(Text, {
    baseStyle: {
        width: {
            desktopWide: '400px',
            tablet: '400px',
            mobile: '100%'
        },
        color: 'white',
        fontSize: {
            desktopWide: '48px',
            tablet: '48px',
            mobile: '40px'
        },
        fontWeight: 600,
        lineHeight: {
            desktopWide: '49px',
            tablet: '49px',
            mobile: '40px'
        },
        margin: {
            desktopWide: '40px 0',
            tablet: '40px 0',
            mobile: '40px 0'
        }
    }
});

export const SubTitle = chakra(Text, {
    baseStyle: {
        color: 'white',
        fontSize: {
            desktopWide: '16px',
            tablet: '16px',
            mobile: '15px'
        },
        fontWeight: 400,
        lineHeight: '25px',
        marginBottom: '40px',
        width: {
            desktopWide: '90%',
            tablet: '90%',
            mobile: '100%'
        }
    }
});

export const MathsLogoSkeleton = chakra(Skeleton, { baseStyle: { ...LogoSize } });

export const IntroImage = chakra(Image, { baseStyle: { ...IntroImageSize } });

export const IntroImageSkeleton = chakra(Image, { baseStyle: { ...IntroImageSize } });
