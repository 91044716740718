import { Image, Text } from '@chakra-ui/react';
import React from 'react';

import birdSrc from '../../../../assets/bird.png';
import { CONFIG } from '../../../../config';
import { ReportTitle, ImageHolder, SignupContent, ReturnToPortalButton } from '../signup-form/signup-form.styles';

export function SignupError () {
    const gotoParentPortal = () => {
        window.open(CONFIG.parentPortal, '_self');
    };

    return (
        <SignupContent>
            <ImageHolder>
                <Image src={birdSrc} marginBottom={'20px'} />
            </ImageHolder>
            <ReportTitle>Uh oh..</ReportTitle>
            <ReportTitle>Something isn&apos;t right here</ReportTitle>
            <Text textStyle={'pageBody'}>This page is having a few issues. <a href={`${CONFIG.mathleticsSupportUrl}`}>Contact us for support
            </a> or return to the Parent Portal.
            </Text>
            <ReturnToPortalButton
                data-testid={'parent-portal-button'}
                size={'large'}
                type={'submit'}
                onClick={gotoParentPortal}
            >
                Return to the Parent Portal
            </ReturnToPortalButton>
        </SignupContent>
    );
}
