import { MathleticsThemeProvider } from '@3plearning/chakra-mathletics-theme';
import React, { PropsWithChildren } from 'react';

import theme from './theme';

export type AppStylesProps = PropsWithChildren<unknown>;

export const AppStylesProvider = ({ children }: AppStylesProps) => {
    return (
        <MathleticsThemeProvider theme={theme}>
            {children}
        </MathleticsThemeProvider>
    );
};
