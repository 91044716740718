export const CONFIG = {
    parentPortal: 'https://www.mathletics.com/au/parent-portal/',
    parentPortalSignUp: 'https://weeklyreport.prod.eastus2.mathletics.com/signup',
    mathleticsHomeUrl: 'https://www.mathletics.com/au/for-home',
    mathleticsForSchoolsUrl: 'https://www.mathletics.com/au/for-schools',
    mathleticsFeaturesUrl: 'https://www.mathletics.com/au/features',
    mathleticsAustralianCurriculumUrl: 'https://www.mathletics.com/au/for-schools/australian-curriculum',
    mathleticsSupportUrl: 'https://support.3plearning.com/?_ga=2.104789248.1218395024.1653260282-1766260375.1651041763',
    mathleticsTechSupportUrl: 'https://www.3plearning.com/tech/mathletics/?_ga=2.104789248.1218395024.1653260282-1766260375.1651041763',
    mathleticsAboutUrl: 'https://www.mathletics.com/au/about',
    mathleticsCareersUrl: 'https://3plearning.com/careers?_ga=2.104789248.1218395024.1653260282-1766260375.1651041763',
    mathleticsContactUrl: 'https://www.mathletics.com/au/contact',
    mathleticsPrivacyUrl: 'http://www.3plearning.com/privacy/?_ga=2.104789248.1218395024.1653260282-1766260375.1651041763',
    mathleticsTermsUrl: 'http://www.3plearning.com/terms/?_ga=2.104789248.1218395024.1653260282-1766260375.1651041763',
    mathleticsReadingEggsUrl: 'https://www.3plearning.com/software/reading-eggs/?_ga=2.138343856.1218395024.1653260282-1766260375.1651041763',
    mathleticsMathSeedsUrl: 'https://www.3plearning.com/software/mathseeds/?_ga=2.138343856.1218395024.1653260282-1766260375.1651041763',
    signInUrl: 'https://parent.3plearning.com/s/login',
    forgotPasswordUrl: 'https://parent.3plearning.com/s/login/ForgotPassword',
    privacyPolicyUrl: 'https://www.3plearning.com/privacy',
    termsAndConditionsUrl: 'https://www.3plearning.com/terms',
    twitterUrl: 'https://twitter.com/mathletics3pl',
    instagramUrl: 'https://instagram.com/mathletics3pl',
    linkedinUrl: 'https://www.linkedin.com/company/3p-learning-mathletics-/',
    youtubeUrl: 'https://www.youtube.com/user/3PLearningGlobal',
    facebookUrl: 'https://www.facebook.com/mathletics',
    contactUsUrl: 'https://support.3plearning.com/contact/'
};
