import { Center } from '@chakra-ui/react';
import React from 'react';

import logoSrc from '../../../../assets/mathletics-logo.svg';
import introImageSrc from '../../../../assets/report-child-600x493.png';
import { IntroImage, IntroImageSkeleton, MathsLogo, MathsLogoSkeleton, SignupIntroBox, SubTitle, Title } from './sign-up.intro.styles';

export function SignupIntro () {
    return (
        <SignupIntroBox>
            <MathsLogo
                data-testid={'mathletics-logo'}
                fallback={
                    <MathsLogoSkeleton data-testid={'mathletics-logo'} />}
                src={logoSrc}
            />
            <Title>
                Weekly Parent Report
            </Title>

            <SubTitle>
                Keep up to-date on your child’s progress by signing up for our parent reports delivered straight
                to your inbox each week.
            </SubTitle>

            <Center>
                <IntroImage
                    data-testid={'intro-image'}
                    fallback={
                        <IntroImageSkeleton data-testid={'intro-image'} />}
                    src={introImageSrc}
                />
            </Center>
        </SignupIntroBox>
    );
}
