import React from 'react';

import birdSrc from '../../../../assets/bird-small.png';
import mathleticsImageSrc from '../../../../assets/mathletics-logo.svg';
import { DisplayMessage } from '../../../../components/display-message/display-message';
import { CONFIG } from '../../../../config';

export function UnsubscribeError () {
    const contentText = (
        <>
            This page is having a few issues - Lets get you back on track. Head back to the Mathletics for Home page
            to sign up for a free trial, purchase subscription or&nbsp;
            <a
                href={`${CONFIG.mathleticsSupportUrl}`}
                target={'_blank'}
                rel={'noreferrer'}
            >
                contact us for support
            </a>
        </>
    );
    const contentImageStyles = {
        marginLeft: '26px',
        width: '267px',
        height: '240px',
        position: 'absolute',
        top: '-30px',
        maxWidth: 'unset'
    };

    return (
        <DisplayMessage
            headerImage={mathleticsImageSrc}
            headerText={'Uh oh...\nSomething isn\'t right here'}
            contentText={contentText}
            contentImage={birdSrc}
            contentImageStyles={contentImageStyles}
        />
    );
}
