import { Box, Link, SkeletonCircle, Image } from '@chakra-ui/react';
import React from 'react';

import logoSrc from '../../../../assets/mathletics-logo.svg';
import { MathsLogo, MathsLogoSkeleton } from '../signup-intro/sign-up.intro.styles';
import {
    FOR_SCHOOLS_LINKS_MENU,
    ABOUT_LINKS_MENU,
    MORE_LINKS_MENU,
    SOCIAL_MEDIA_LINKS,
    LinkMenuData,
    SocialMediaMenuData
} from './links-menu';
import {
    LinkStyles,
    FlexColumn,
    ListTitle,
    SignupFooterStyled,
    LineSpacer,
    FooterContent,
    LinksContent,
    FooterLinks,
    BottomFooter,
    CopyText,
    SocialLinks,
    SocialLinksContainer,
    LogoStyles
} from './signup-footer.styles';

export function SignupFooter () {
    const gotoPage = (url: string) => {
        window.open(url);
    };
    const renderMenuSection = (linkMenu: LinkMenuData) => {
        return (
            <>
                <ListTitle>{linkMenu.title}</ListTitle>
                <FlexColumn>
                    {
                        linkMenu.links.map((item) => (
                            <Link
                                data-testid={item.label}
                                key={`linkId-${item.label}`}
                                sx={LinkStyles}
                                href={item.url}
                                isExternal
                            >
                                {item.label}
                            </Link>
                        ))
                    }
                </FlexColumn>
            </>
        );
    };
    const renderSocialMediaSection = (socialMediaList: SocialMediaMenuData) => {
        return (
            socialMediaList.map((item) => (
                <Image
                    key={`socialMediaImage-${item.label}`}
                    sx={LogoStyles}
                    src={item.iconSrc}
                    fallback={<SkeletonCircle sx={LogoStyles} />}
                    onClick={() => gotoPage(item.url)}
                />
            ))
        );
    };

    return (
        <SignupFooterStyled data-testid={'signup-footer'}>
            <FooterContent>
                <MathsLogo
                    data-testid={'mathletics-logo'}
                    fallback={
                        <MathsLogoSkeleton data-testid={'mathletics-logo'} />}
                    src={logoSrc}
                />
                <LinksContent>
                    <FooterLinks>
                        <Box>
                            {renderMenuSection(FOR_SCHOOLS_LINKS_MENU)}
                        </Box>
                        <Box>
                            {renderMenuSection(ABOUT_LINKS_MENU)}
                        </Box>
                        <Box>
                            {renderMenuSection(MORE_LINKS_MENU)}
                        </Box>
                    </FooterLinks>
                    <LineSpacer />
                    <BottomFooter>
                        <CopyText>Copyright 2022. 3P Learning - The teaching moments that inspire learning.</CopyText>
                        <SocialLinksContainer>
                            <SocialLinks>
                                {renderSocialMediaSection(SOCIAL_MEDIA_LINKS)}
                            </SocialLinks>
                        </SocialLinksContainer>
                    </BottomFooter>
                </LinksContent>
            </FooterContent>
        </SignupFooterStyled>
    );
}
